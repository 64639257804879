export default {
  auth: {
    clientId: "14fb436a-f0b7-481d-81c5-6bd189541843",
    authority:
      "https://login.microsoftonline.com/64391119-a32d-4c70-aa4c-daebed404c71/",
    redirectUri: window.location.origin
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false
  },
  loginRequest: ["profile", "user.read"],
  tokenRequest: ["api://01846ddc-c5ae-4550-94ba-255927f0680b/user.read"],
  response_type: "token",
  priceListApi: "https://sald-api.azure-api.net/api/price/v1.1/",
  orderApi: "https://sald-api.azure-api.net/api/order/v1.1/",
  apiKey: "3d35a43a077141bfb6d9f2794629e4da"
};
