import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    registrations: [],
    users: [],
    currentUser: [],
    selectedQtyItems: []
  }
});
export default store;
