import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
import App from "./App";
import router from "./router";
import store from "./store";
import AuthService from "./msal";

Vue.prototype.$AuthService = new AuthService();

// Import Bootstrap an BootstrapVue CSS files (keep the same order)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);

// Ag-grid styles available throughout your project
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

Vue.config.productionTip = false;

import * as Msal from "@azure/msal-browser";
import msalConfig from "./config";

const myMsal = new Msal.PublicClientApplication(msalConfig);
Vue.prototype.$myMsal = myMsal;

const currentAccounts = myMsal.getAllAccounts();
if (currentAccounts.length) {
  store.state.currentUser = currentAccounts[0];
  createVueApp();
} else {
  myMsal.handleRedirectPromise().then(handleResponse);
}

function handleResponse() {
  const getUser = myMsal.getAllAccounts();
  if (!getUser.length) {
    myMsal.loginRedirect(msalConfig.loginRequest);
  } else {
    store.state.currentUser = getUser[0];
    createVueApp();
  }
}

function createVueApp() {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount("#app");
}
