
<script>
import axios from "axios"
export default {
  name: "LoginForm",
  components: {
    axios
  },
  data() {
    return {
      userNme: "",
      passWrd: "",
      loginMsg: "Redirecting to Login Page... Please Wait.!",
      user: null,
      customers: [
        {
          name: "Dana",
          userName: "dana",
          customerId: 148,
          billTo: "Dana International",
          password: "dana",
        },
        {
          name: "Massy",
          userName: "massy",
          customerId: 149,
          billTo: "Massy Barbados",
          password: "massy",
        },
      ],
    };
  },
  created() {
    this.setUser()
  },
  methods: {
    setUser() {
      this.userData = this.$userAuth[0]
    },
  },
};
</script>
