import Vue from 'vue'
import * as Msal from "@azure/msal-browser"
import msalConfig from '../config'

export default class AuthService {
  constructor() {
    this.myMsal = new Msal.PublicClientApplication(msalConfig)
  }

  getCurrentUser() {
    return this.myMsal.getAllAccounts()?this.myMsal.getAllAccounts() : false
  }

  signIn() {
    let getUser = this.getCurrentUser()
    if (getUser.length > 0) {alert('if1')
      return getUser
    } else {alert('else1')
      // this.myMsal.handleRedirectPromise().then(this.handleRedirectPromise)
      this.myMsal.loginRedirect(msalConfig.loginRequest)
    }
  }

  handleResponse() {
    const getUser = this.myMsal.getAllAccounts()
    if (getUser.length > 0) {alert('if')
      return getUser
    } else {alert('else')
      this.myMsal.loginRedirect(msalConfig.loginRequest)
    }
  }
  
  getAuthToken() {
    const account = this.getCurrentUser()
    const accessTokenRequest = {
        scopes: msalConfig.loginRequest,
        account: account
    }
    this.myMsal.acquireTokenSilent(accessTokenRequest).then(function(accessTokenResponse) {
        // Acquire token silent success
        // Call API with token
        let accessToken = accessTokenResponse.accessToken
        // Call your API with token
      console.log('accessToken' + accessTokenResponse)
      return accessTokenResponse.accessToken
    }).catch(function (error) {
        //Acquire token silent failure, and send an interactive request
        console.log('error'+error)
        if (error instanceof InteractionRequiredAuthError) {
            publicClientApplication.acquireTokenRedirect(accessTokenRequest)
        }
    })
  }

  signOut() {
    this.myMsal._user = null
    this.myMsal.logout()
  }

  // Utility
  getUser() {
    return this.getCurrentUser()
  }

}


// const msalAuthService = new AuthService()
// const currentAccounts = msalAuthService.getCurrentUser()
// const signIn = msalAuthService.signIn()

// console.log('signIn' + JSON.stringify(signIn))

// if (!signIn) {
//   Vue.prototype.$userAuth = currentAccounts[0]
//   Vue.prototype.$accessToken = msalAuthService.getAuthToken()
//   console.log('currentAccounts' + JSON.stringify(msalAuthService.getAuthToken()))
//   createVueApp()
// } else {
//   msalAuthService.myMsal.handleRedirectPromise().then(handleResponse)
// }

// function handleResponse() {
//   const getUser = msalAuthService.getCurrentUser()
//   if (!currentAccounts) {
//       msalAuthService.myMsal.handleRedirectPromise().then(handleResponse)
//   } else {
//     Vue.prototype.$userAuth = getUser[0]
//     msalAuthService.getAuthToken()
//     createVueApp()
//   }
// }