export default {
  template:
    '<input type="text" autoComplete="off" :value="imgForMood" style="width:80px;height:30px;border: 1px solid #727070;padding-left:5px;"/>',
  data() {
    return {
      mood: "Happy",
      imgForMood: null
    };
  },
  methods: {
    refresh(params) {
      this.params = params;
      this.setMood(params);
    },

    setMood(params) {
      this.mood = params.value;
      this.imgForMood = this.mood;
    }
  },
  created() {
    this.setMood(this.params);
  }
};
