<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App"
};
</script>

<style lang="scss">
#app {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-size: 14px;
  line-height: normal;
  color: #333;
  h6,
  .h6 {
    font-size: 1.25rem;
    margin-bottom: 0;
    margin-top: 0.25rem;
  }
  .container-fluid {
    margin: 0 auto;
    padding: 0 !important;
  }
  h2 {
    text-align: center;
    position: relative;
    bottom: 2rem;
  }
  .orderForm label {
    font-size: 0.9rem;
    font-weight: 600;
  }
  nav {
    height: 60px;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    background-color: #14377d;
    padding: 0 20px;
    margin-bottom: 3rem;
    .logo {
      font-weight: bold;
      color: white;
      position: relative;
      top: 1.5rem;
      right: 3rem;
      width: 20rem;
      text-align: center;
    }
    .logo a {
      text-decoration: none;
      color: white;
      font-size: 1.25rem;
    }
    .navbar-nav {
      padding: 30px;
      li {
        margin: 0;
        &.nav-item {
          padding: 0 0.5em;
          &:not(:last-child) {
            border-right: 2px solid #fff;
          }
        }
        a {
          font-size: 1rem;
          font-weight: normal;
          color: #fff;
          &.router-link-exact-active {
            color: yellow;
          }
        }
      }
    }
  }
  ul,
  ol {
    list-style: none;
    margin: 0;
    padding: 0 !important;
    height: 100%;
  }

  li {
    margin: 0 16px 0 0;
  }

  li a {
    text-decoration: none;
    color: white;
  }
  .swicthType {
    padding: 0;
    span {
      font-weight: 600;
      font-size: 1rem;
    }
    .switchTxt {
      padding: 0;
      span {
        font-weight: 600;
        font-size: 1rem;
      }
    }
    .dcList {
      padding: 0;
    }
  }
  .welcomeTxt {
    color: white;
    font-size: 1rem;
    margin: 1rem;
  }
  .logout {
    background-color: transparent;
    border: none;
    font: inherit;
    color: white;
    cursor: pointer;
  }
  .items-count p {
    margin: 1rem 0;
    font-size: 1rem;
    font-weight: 600;
    color: gray;
  }
  .pricelistTitle {
    padding: 0 1rem 0 0;
    float: left;
    line-height: 2.6rem;
  }
  .datePicker {
    label.sr-only {
      display: none;
    }
  }
  .filter-section {
    margin: 0;
    ul {
      float: left;
    }
    select {
      font-size: 14px;
      max-width: 25rem;
      padding: 0.7rem 3rem 0.7rem 1rem;
      margin-left: 0;
      text-align: left;
      border: 1px solid #ced4da;
      color: #14377d;
      font-weight: 600;
    }
  }
  .selectedCount {
    margin: 0 0 1rem 0;
    font-size: 1.2rem;
    font-weight: 600;
    color: #14377d;
    .text-start {
      padding: 1rem 0 0 0;
    }
    p {
      margin: 0;
      min-height: 1rem;
    }
    button {
      font-size: 0.95rem;
      margin-top: 1rem;
      &:disabled {
        background-color: gray;
        border: gray;
      }
    }
  }
  .grow {
    flex: 1;
  }
  .main {
    overflow-y: auto;
  }
  input.qtyCol {
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
  .grid-cell-number {
    padding: 0 25px;
  }
  .grid-cell-center {
    text-align: center;
  }
  .grid-cell-left {
    text-align: left;
  }
  .grid-cell-right {
    text-align: right;
  }
  .ag-header-cell-text {
    text-overflow: clip;
    white-space: break-spaces;
    text-align: center;
  }
  .ag-theme-alpine .ag-cell,
  .ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group {
    input {
      outline: 0;
    }
  }
}
</style>
