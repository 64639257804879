import Vue from 'vue'
import VueRouter from 'vue-router'
import PriceList from '../views/PriceList.vue'
import OrderForm from '../views/OrderForm.vue'
import LoginForm from '../views/LoginForm.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect: {
        name: 'PriceList'
    }
  },
  {
    path: '/login',
    name: 'LoginForm',
    component: LoginForm
  },
  {
    path: '/pricelist',
    name: 'PriceList',
    component: PriceList
  },
  {
    path: '/orderform',
    name: 'OrderForm',
    component: OrderForm
  },
  {
    path: '/orders',
    name: 'OrderHistory',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/OrderHistory.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

//To avoid redundant navigation when using push method
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((error) => {
  })
}

export default router
