<template>
  <div class="priceList">
    <div class="container-fluid px-4 px-lg-5">
      <div class="row w-100 mx-auto">
        <app-header :userDetails="getUser"></app-header>

        <div class="row text-start">
          <div class="col-10 text-start">
            <div>&nbsp;</div>
            <div class="row swicthType">
              <div class="filter-section dc-filter col-8">
                <div class="row">
                  <div class="col-2"><h6>Price List for</h6></div>
                  <div class="col-10">
                    <h6><b>Customer: </b>{{ customerName }}</h6>
                  </div>
                </div>
              </div>
              <div class="col-4 dcList">
                <h6><b>DC: </b> {{ priceListFor }}</h6>
              </div>
            </div>
            <div class="row packDetails mb-1 mt-4">
              <div class="col-3">
                <p>Total Order Weight= {{ totalWeight }}</p>
              </div>
              <div class="col-3">
                <p>Total Order Cube= {{ totalCube }}</p>
              </div>
              <div class="col-3">
                <p v-if="selectedRowsCount > 1">
                  {{ selectedRowsCount }} items selected
                </p>
                <p v-else-if="selectedRowsCount === 1">
                  {{ selectedRowsCount }} item selected
                </p>
                <p v-else></p>
              </div>
            </div>
          </div>

          <div class="col-2 selectedCount text-end" v-if="!showOrderForm">
            <button
              type="button"
              class="btn btn-lg btn-primary"
              @click="orderFormRedirect"
              :disabled="!selectedRowsCount"
            >
              + ADD TO ORDER FORM
            </button>
          </div>
        </div>

        <div class="main col-12 grid-section">
          <!-- <button @click="getSelectedRows()">Get Selected Rows</button> -->
          <div class="row">
            <ag-grid-vue
              style="width: 100%; height: 400px;"
              class="ag-theme-alpine"
              id="priceListGrid"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :rowData="rowData"
              :rowSelection="rowSelection"
              :pagination="true"
              :animateRows="true"
              :overlayLoadingTemplate="overlayLoadingTemplate"
              :overlayNoRowsTemplate="overlaynoRowsTemplate"
              :stopEditingWhenCellsLoseFocus="true"
              :enableBrowserTooltips="true"
              @sortChanged="onSortChanged"
              @first-data-rendered="onFirstDataRendered"
              @cell-value-changed="onCellValueChanged"
              @grid-ready="onGridReady"
            >
            </ag-grid-vue>
          </div>
          <div class="row items-count text-start">
            <p>Total {{ itemsCount }} items found</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import Header from "@/components/Header.vue";
import axios from "axios";
import storeUsers from "@/assets/users.json";
import * as Msal from "@azure/msal-browser";
import msalConfig from "@/config";
import numericEditor from "@/common/js/numericEditor";
import qtyInputRenderer from "@/common/js/qtyInputRenderer";

export default {
  name: "PriceList",
  data() {
    return {
      columnDefs: null,
      rowData: [],
      gridApi: null,
      columnApi: null,
      gridOptions: null,
      postSort: "",
      overlayLoadingTemplate:
        '<span class="ag-overlay-loading-center">Loading Price List data…</span>',
      overlaynoRowsTemplate:
        '<span class="ag-overlay-loading-center">Pricelist unavailable. Try again later.</span>',
      autoGroupColumnDef: null,
      rowSelection: null,
      itemsCount: 0,
      priceListFor: "",
      DCnumber: "06",
      selectedRowsCount: 0,
      showOrderForm: false,
      validForm: false,
      selectedItems: [],
      totalWeight: null,
      totalCube: null,
      quantityDef: 0,
      quantityUpdated: null,
      quantity: "",
      totalWeight: 0,
      totalCube: 0,
      userZoneID: "",
      userLoggedIn: null,
      getUser: "",
      user: null,
      storeUsers: storeUsers,
      accessTokenId: "",
      customerName: ""
    };
  },
  components: {
    AgGridVue,
    "app-header": Header,
    Msal,
    numericEditor,
    qtyInputRenderer
  },
  beforeMount() {
    this.defaultColDef = {
      flex: 1,
      resizable: true,
      supressMenu: true,
      sortable: true,
      floatingFilter: true,
      sortingOrder: ["desc", "asc"]
    };
    this.rowSelection = false;
    this.columnDefs = [
      {
        headerName: "Cases Ordered",
        field: "quantity",
        minWidth: 120,
        editable: true,
        singleClickEdit: true,
        cellRendererFramework: "qtyInputRenderer",
        cellEditorFramework: "numericEditor",
        filter: true,
        sortable: true,
        comparator: (a, b, nodeA, nodeB, isInverted) => {
          this.gridApi.paginationGoToFirstPage();
          if (a === b) {
            return 0;
          } else if (!a) {
            return isInverted ? -1 : 1;
          } else if (!b) {
            return isInverted ? 1 : -1;
          } else {
            return a.localeCompare(b);
          }
        }
      },
      {
        headerName: "Brand/Class",
        field: "brandClass",
        minWidth: 140,
        sort: "asc",
        filter: true,
        cellClass: "grid-cell-number",
        tooltipField: "brandClass"
      },
      {
        headerName: "Item ID/Description",
        field: "itemDetails",
        minWidth: 240,
        filter: true,
        tooltipField: "itemDetails"
      },
      { headerName: "UPC", field: "UPC", minWidth: 120, filter: true },
      {
        headerName: "Price($)",
        field: "price",
        minWidth: 100,
        cellClass: "grid-cell-number"
      },
      {
        headerName: "Pack",
        field: "pack",
        filter: true,
        minWidth: 100,
        cellClass: "grid-cell-number"
      },
      { headerName: "Size", field: "size", filter: true, minWidth: 100 },
      {
        headerName: "Weight",
        field: "weight",
        minWidth: 100,
        cellClass: "grid-cell-number"
      },
      { headerName: "Cube", field: "cube", minWidth: 100 },
      { headerName: "TI", field: "HI", minWidth: 100 },
      { headerName: "HI", field: "HI", minWidth: 100 },
      {
        headerName: "Status",
        field: "status",
        filter: true,
        minWidth: 120
      }
    ];

    this.setUser();
    this.setUserStores();
    this.getAuthToken();
  },
  mounted() {
    // this.gridApi = this.gridOptions.api
    // this.columnApi = this.gridOptions.columnApi
  },
  methods: {
    setUser() {
      this.userData = this.$store.state.currentUser;
    },
    onSortChanged(evt) {
      // this.gridApi.paginationGoToFirstPage();
    },
    trimeVal(val) {
      let newVal = val.trim();
      let trimVal = newVal.toLowerCase();
      return trimVal;
    },
    setUserStores() {
      let userDet = {};
      storeUsers.forEach(ele => {
        if (
          ele.email &&
          this.trimeVal(this.userData.username) === this.trimeVal(ele.email)
        ) {
          userDet.userZoneID = ele.storeId;
          userDet.customerName = ele.customerName;
          userDet.accountNo = ele.accountNo;
        }
      });

      if (userDet && userDet.customerName) {
        this.userZoneID = userDet.storeId;
        this.customerName = userDet.customerName;
        this.accountNo = userDet.accountNo;
      } else {
        this.userZoneID = "149";
        this.customerName = "Save-A-Lot Internal";
        this.accountNo = this.userZoneID;
      }
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    onFirstDataRendered(params) {
      const routePath = this.$router.history.current.params.routePath;
      if (sessionStorage.userSelectedItems) {
        this.retainQtyInput(this.rowData, routePath);
        this.updateQtyDependencies();

        if (routePath === "orderForm") {
          const sort = [
            {
              colId: "quantity",
              sort: "desc"
            }
          ];

          this.gridApi.setSortModel(sort);
        }
      }
      this.focusQtyColumn();
    },
    focusQtyColumn() {
      //Focus on quantity input
      this.gridApi.sizeColumnsToFit();
      this.gridApi.setFocusedCell(0, "quantity");
      this.gridApi.startEditingCell({
        rowIndex: 0,
        colKey: "quantity"
      });
    },
    displayCubeDetails() {
      var totalW = 0;
      var totalC = 0;
      this.rowData.forEach((e, i) => {
        if (e.quantity > 0) {
          totalW += e.weight * e.quantity;
          totalC += e.cube * e.quantity;
        }
      });
      this.totalWeight = parseFloat(totalW).toFixed(2);
      this.totalCube = parseFloat(totalC).toFixed(2);
      this.quantityUpdated = true;
    },
    getSelectedDataRows() {
      const changedData = this.rowData.filter(function(itemData) {
        if (itemData.quantity > 0) {
          return {
            whse: itemData.whse,
            brandClass: itemData.brand + " - " + itemData.class,
            brand: itemData.brand,
            class: itemData.class,
            itemDetails:
              itemData.item_number + " - " + itemData.item_description,
            item_number: itemData.item_number,
            item_description: itemData.item_description,
            item_seq: 1,
            UPC: itemData.UPC,
            pack: itemData.pack,
            size: itemData.size,
            weight: itemData.weight,
            cube: itemData.cube,
            TI: itemData.TI,
            HI: itemData.HI,
            price: itemData.price,
            status: itemData.status,
            quantity: itemData.quantity
          };
        }
      });

      return changedData;
    },
    updateQtyDependencies() {
      const changedData = this.getSelectedDataRows();

      this.selectedRowsCount = changedData.length;
      this.selectedItems = changedData;

      if (this.selectedRowsCount) {
        this.displayCubeDetails();
      }
    },
    onCellValueChanged(params) {
      this.updateQtyDependencies();
    },
    retainQtyInput(allRows, routePath) {
      const selectedRows = JSON.parse(sessionStorage.userSelectedItems);

      if (selectedRows) {
        const updatedQty = allRows.map(element => {
          const newObject = { ...element };

          selectedRows.map(row => {
            if (row.item_number === element.item_number) {
              Object.keys(newObject).map(property => {
                if (property === "quantity") {
                  newObject[property] = row.quantity > 0 ? row.quantity : "";
                }
              });
            }
          });
          return newObject;
        });
        this.rowData = updatedQty;

        this.orderFormBtnState();
      }
    },
    orderFormBtnState() {},
    orderFormRedirect() {
      // this.$store.state.selectedQtyItems = this.selectedItems;
      sessionStorage.setItem(
        "userSelectedItems",
        JSON.stringify(this.selectedItems)
      );
      sessionStorage.setItem("priceListFor", JSON.stringify(this.priceListFor));
      sessionStorage.setItem("DCnumber", JSON.stringify(this.DCnumber));
      this.$router.push({
        name: "OrderForm",
        params: { DCdesc: this.priceListFor, DCnumber: this.DCnumber }
      });
    },
    async getAuthToken() {
      const myMsal = new Msal.PublicClientApplication(msalConfig);
      const account = myMsal.getAllAccounts()[0];
      const accessTokenRequest = {
        scopes: msalConfig.tokenRequest,
        account: account
      };
      const idToken = await myMsal
        .acquireTokenSilent(accessTokenRequest)
        .then(function(accessTokenResponse) {
          let accessToken = accessTokenResponse.accessToken;
          return accessToken;
        })
        .catch(err => {
          if (err.name === "InteractionRequiredAuthError") {
            return msalnstance
              .acquireTokenSilent(accessTokenRequest)
              .then(response => {});
          }
        });
      this.priceListRequest(idToken);
    },
    priceListRequest(token) {
      this.setUser();

      const reqBody = {
        PriceList: {
          msgheader: {
            businessfield01: "PriceList",
            correlationid: "1234-4456-5677",
            countryoforigin: "US",
            msgtype: "price",
            resubmitflag: "no",
            resubmittargetsysid: "null",
            sbsessionid: "null",
            sourcesysid: "gom-web",
            sourcesysmsgid: this.accountNo,
            sourcetimestamp: "2021-08-16T15:59:07Z",
            targetsysid: "edw"
          },
          msgbody: {
            filter: "all"
          }
        }
      };
      const headers = {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": "3d35a43a077141bfb6d9f2794629e4da",
        "Access-Control-Allow-Origin": "true",
        Authorization: "Bearer " + token
      };
      this.gridApi.showLoadingOverlay();
      axios
        .post(msalConfig.priceListApi, reqBody, { headers })
        .then(response => {
          // For pricelist 1.1
          const resRows = response.data.PriceListResponse.DC;
          const fetchedRows = resRows[0].PriceList;
          this.itemsCount = fetchedRows.length;
          this.priceListFor = response.data.PriceListResponse.DC[0].Description;
          this.DCnumber = this.priceListFor.trim().substring(0, 2);
          if (fetchedRows.length > 0) {
            this.rowData = fetchedRows.map(data => {
              return {
                whse: data.whse,
                brandClass: data.brand + " - " + data.class,
                itemDetails: data.item_number + " - " + data.item_description,
                item_number: data.item_number,
                item_description: data.item_description,
                item_seq: 1,
                UPC: data.UPC,
                pack: data.pack,
                size: data.size,
                weight: data.weight,
                cube: data.cube,
                TI: data.TI,
                HI: data.HI,
                price: parseFloat(data.price).toFixed(2),
                status: data.item_status,
                quantity: ""
              };
            });
          } else {
            this.gridApi.showNoRowsOverlay();
          }

          this.gridApi.hideOverlay();
        })
        .catch(error => {
          this.errorMessage = error.message;
          console.error("There was an error!", this.errorMessage);
          if (error.message) {
            this.gridApi.showNoRowsOverlay();
          }
        });
    }
  }
};
</script>

<style lang="scss">
#app {
  .ag-theme-alpine .ag-floating-filter-button-button {
    display: none;
  }
  .ag-header-cell-menu-button {
    display: none;
  }
  .ag-input-wrapper,
  .ag-picker-field-wrapper {
    input:before {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      content: "\f007";
    }
  }
  .packDetails {
    font-size: 1rem;
    font-weight: 600;
  }
  .customQty {
    line-height: inherit;
    color: inherit;
    border-width: 1px;
    border-style: solid;
    border-color: #babfc7;
    border-color: var(--ag-input-border-color, var(--ag-border-color, #babfc7));
    padding: 0;
    margin: 0;
  }
}
</style>
