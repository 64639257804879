<template>
  <div class="container-fluid">
    <!-- Responsive navbar-->
    <nav class="navbar navbar-expand-lg">
      <div class="container-fluid px-0 mx-0">
        <div class="logo">
          <span>
            <img alt="logo" src="../assets/logo-sal.png" width="25%"
          /></span>
        </div>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="navbar-collapse" id="navbarSupportedContent">
          <ul class="nav navbar-nav mx-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <a
                href="javascript:void(0)"
                @click="
                  $router.push({
                    name: 'PriceList',
                    params: { routePath: 'orderForm' }
                  })
                "
                >PRICE LIST</a
              >
            </li>
            <li class="nav-item">
              <router-link to="/orders">ORDER HISTORY</router-link>
            </li>
          </ul>
        </div>
        <div class="userDetails">
          <p class="welcomeTxt align-middle">
            Welcome {{ userName }},
            <button
              @click="logout"
              class="btn btn-secondary btn-md btn-block"
              style="background: #14377D;border:0;position:relative;left:2rem;"
              type="submit"
            >
              Logout
            </button>
          </p>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: {
    userDetails: {
      required: true
    }
  },
  data() {
    return {
      userData: null,
      userName: ""
    };
  },
  mounted() {
    this.setUser();
    if (!this.userData.homeAccountId) {
      this.$myMsal.loginRedirect(loginRequest);
    }
  },
  beforeMount() {
    this.setUser();
  },
  methods: {
    logout() {
      sessionStorage.priceListFor = null;
      sessionStorage.userSelectedItems = null;
      this.$myMsal.logout();
    },
    setUser() {
      this.userData = this.$store.state.currentUser;
      this.userName = this.userData.name
        ? this.userData.name
        : this.userData.username;
    }
  }
};
</script>
