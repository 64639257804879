<template>
  <div>
    <b-modal
      id="modal-multi-2"
      title="Your Order is Placed Successfully.!"
      hide-footer
    >
      <p class="my-2">{{ responseMsg }}</p>
      <p>&nbsp;</p>
    </b-modal>

    <b-modal
      id="modal-multi-3"
      title="Your order submission failed.!"
      hide-footer
    >
      <p class="my-2">{{ responseMsg }}</p>
    </b-modal>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import OrderForm from "@/views/OrderForm.vue";

export default {
  name: "Modal",
  props: {
    responseMsg: {
      type: String
    },
    confirmedItems: {
      required: true
    }
  },
  data() {
    return {
      columnDefs: null,
      dataRows: null,
      gridApi: null,
      columnApi: null,
      autoGroupColumnDef: null,
      orderRef: null,
      resMsg: "",
      itemSeq: 1,
      pickupDate: "",
      deliveryDate: "",
      accNo: "",
      deliveryAddress: "",
      address: "",
      billName: ""
    };
  },
  components: {
    AgGridVue,
    OrderForm
  },
  beforeMount() {},
  mounted() {
    this.$root.$on("bv::modal::show", bvEvent => {
      if (bvEvent.type === "show") {
        this.dataRows = this.confirmedItems;
      }
    });
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },
    redirectToHome() {
      this.$router.push({ name: "PriceList", params: "" });
    }
  }
};
</script>
