<template>
  <div class="orderForm">
    <div class="container-fluid">
      <div class="row grow w-100 mx-auto">
        <Header :userDetails="getUser"></Header>

        <div class="container-wrap">
          <form
            class="row form-floating"
            name="orderForm"
            @submit.prevent="confirmOrder"
          >
            <div class="row">
              <div class="titleSection text-left col-2">
                <h6><b>Order Form</b></h6>
              </div>
              <div class="titleSection text-center col-3">
                <h6><b>DC: </b>{{ priceListFor }}</h6>
              </div>
              <div class="titleSection text-left col-3">
                <h6><b>Submission Date:</b> {{ submissionDate }}</h6>
              </div>
              <div class="titleSection text-center col-4 float-end">
                <button
                  type="submit"
                  name="orderForm"
                  @click="resetRes"
                  class="btn btn-primary btn-order mb-3"
                >
                  SUBMIT ORDER FORM
                </button>
                <span style="margin-left:10px;"></span>
                <button
                  type="submit"
                  name="orderForm"
                  @click="
                    $router.push({
                      name: 'PriceList',
                      params: { routePath: 'orderForm' }
                    })
                  "
                  class="btn btn-outline-success btn-sm mb-3"
                >
                  ADD MORE ITEMS
                </button>

                <button
                  type="button"
                  class="btn btn-info btn-excel mb-3"
                  @click="onBtnExportExcel"
                  title="Export items to Excel"
                >
                  <i class="fa fa-file-excel"></i> EXPORT ITEMS
                </button>
              </div>
            </div>

            <Modal
              :responseMsg="responseMsg"
              :confirmedItems="confirmedItems"
            ></Modal>

            <div class="row mx-auto">
              <b-card no-body class="mb-1 orderFormHeader">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button
                    block
                    href="#"
                    v-b-toggle.accordion-1
                    variant="light"
                    class="text-start"
                    style="width: 100%"
                  >
                    <span class="float-end"
                      ><i class="fas fa-chevron-down"></i></span
                    ><span class="float-end"
                      ><i class="fas fa-chevron-up"></i></span
                  ></b-button>
                </b-card-header>
                <b-collapse
                  id="accordion-1"
                  visible
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <div class="col-11 orderForm">
                      <div class="row mb-3">
                        <div class="col-md-3">
                          <label for="accountNo">Account Number:</label>
                          <input
                            type="text"
                            placeholder="Account No"
                            class="form-control"
                            v-model="accountNo"
                            name="accountNo"
                            required
                            readonly
                          />
                        </div>
                        <div class="col-md-3">
                          <label for="poNumber">PO Number:</label>
                          <input
                            type="text"
                            placeholder="PO Number"
                            class="form-control"
                            v-model="poNumber"
                            name="poNumber"
                            style="border: 1px solid #727070;"
                            required
                          />
                        </div>
                        <div class="col-md-3">
                          <label for="customerName">Bill To:</label>
                          <input
                            type="text"
                            placeholder="Bill to:"
                            class="form-control"
                            v-model="customerName"
                            name="customerName"
                            :title="customerName"
                            required
                            readonly
                          />
                        </div>

                        <div class="col-md-3 datePicker mx-0">
                          <div class="row">
                            <div class="col-md-6 px-0">
                              <label for="pickupDate"
                                >{{
                                  pickupCheck ? `PickUp` : `Delivery`
                                }}
                                Date:</label
                              >
                              <b-input-group class="mb-3">
                                <b-form-datepicker
                                  button-only
                                  left
                                  locale="en-US"
                                  aria-controls="pickupDate"
                                  :min="minDate"
                                  v-model="pickupDate"
                                  @context="pickupContext"
                                ></b-form-datepicker>
                                <b-form-input
                                  name="pickupDate"
                                  :placeholder="
                                    pickupCheck ? `PickUp` : `Delivery`
                                  "
                                  v-model="pickupDate"
                                  type="text"
                                  autocomplete="off"
                                  required
                                ></b-form-input>
                              </b-input-group>
                            </div>
                            <div class="col-md-6 px-0">
                              <label for="pickupCheck"></label>
                              <b-input-group class="mt-2">
                                <input
                                  type="checkbox"
                                  id="pickupCheck"
                                  v-model="pickupCheck"
                                  name="pickupDate"
                                  class="mt-1"
                                />
                                <label for="pickupCheck">PickUp</label>
                              </b-input-group>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row mb-0">
                        <div class="col-md-6 px-3">
                          <h6 class="mb-2 mt-2">Billing Address</h6>
                          <div class="row mb-3">
                            <div class="col-md-12">
                              <input
                                type="text"
                                placeholder="Billing Address"
                                title="Address line"
                                class="form-control"
                                v-model="billingAddress"
                                name="billingAddress"
                                required
                                readonly
                              />
                            </div>
                          </div>

                          <div class="row mb-3">
                            <div class="col-md-6">
                              <input
                                type="text"
                                placeholder="City"
                                title="City"
                                class="form-control"
                                v-model="billingCity"
                                name="billingCity"
                                required
                                readonly
                              />
                            </div>
                            <div class="col-md-6">
                              <input
                                type="text"
                                placeholder="Country"
                                title="Country"
                                class="form-control"
                                v-model="billingCountry"
                                name="billingCountry"
                                required
                                readonly
                              />
                            </div>
                          </div>

                          <div class="row mb-3">
                            <div class="col-md-6">
                              <input
                                type="text"
                                placeholder="State"
                                title="State"
                                class="form-control"
                                v-model="billingState"
                                name="billingState"
                                required
                                readonly
                              />
                            </div>
                            <div class="col-md-6">
                              <input
                                type="text"
                                placeholder="Zip code"
                                title="Zip code"
                                class="form-control"
                                v-model="billingZipCode"
                                name="billingZipCode"
                                required
                                readonly
                              />
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6 px-3">
                          <h6 class="mb-2 mt-2">Delivery Address</h6>
                          <div class="row mb-3">
                            <div class="col-md-12">
                              <input
                                type="text"
                                placeholder="Delivery Address"
                                title="Delivery address"
                                class="form-control"
                                v-model="deliveryAddress"
                                name="deliveryAddress"
                                required
                                readonly
                              />
                            </div>
                          </div>

                          <div class="row mb-3">
                            <div class="col-md-6">
                              <input
                                type="text"
                                placeholder="City"
                                title="City"
                                class="form-control"
                                v-model="deliveryCity"
                                name="deliveryCity"
                                required
                                readonly
                              />
                            </div>
                            <div class="col-md-6">
                              <input
                                type="text"
                                placeholder="Country"
                                title="Country"
                                class="form-control"
                                v-model="deliveryCountry"
                                name="deliveryCountry"
                                required
                                readonly
                              />
                            </div>
                          </div>

                          <div class="row mb-3">
                            <div class="col-md-6">
                              <input
                                type="text"
                                placeholder="State"
                                title="State"
                                class="form-control"
                                v-model="deliveryState"
                                name="deliveryState"
                                required
                                readonly
                              />
                            </div>
                            <div class="col-md-6">
                              <input
                                type="text"
                                placeholder="Zip code"
                                title="Zip code"
                                class="form-control"
                                v-model="deliveryZipCode"
                                name="deliveryZipCode"
                                required
                                readonly
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col-md-6 px-3"></div>
                        <div class="col-md-6 px-3">
                          <label for="customerNotes">Notes:</label>
                          <textarea
                            title="Notes"
                            class="form-control customerNotes"
                            v-model="customerNotes"
                            name="customerNotes"
                          />
                        </div>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>

            <div class="row packDetails mb-3" v-if="quantityUpdated">
              <div class="col-3">Total Order Weight= {{ totalWeight }}</div>
              <div class="col-3">Total Order Cube= {{ totalCube }}</div>
            </div>

            <div class="main col-12 grid-section">
              <!-- <button @click="getSelectedRows()">Get Selected Rows</button> -->
              <div class="row">
                <ag-grid-vue
                  id="formGrid"
                  style="width: 100%; height: 300px;"
                  class="ag-theme-alpine"
                  :columnDefs="columnDefs"
                  :defaultColDef="defaultColDef"
                  :rowData="selectedRowData"
                  :rowSelection="rowSelection"
                  :pagination="true"
                  @selection-changed="onSelectionChanged"
                  @grid-ready="onGridReady"
                >
                </ag-grid-vue>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import Header from "@/components/Header.vue";
import Modal from "@/components/Modal.vue";
import axios from "axios";
import storeUsers from "@/assets/users.json";
import * as Msal from "@azure/msal-browser";
import msalConfig from "@/config";
import store from "../store";

export default {
  name: "OrderForm",
  data() {
    let today = new Date();

    let submissionDate = today.toISOString().split("T")[0];
    let currentTimeStamp = today.toISOString();

    let submissionDateStr = today
      .toISOString("en-US", {
        day: "numeric",
        month: "numeric",
        year: "numeric",
        hour12: false,
        hour: "numeric",
        minute: "numeric"
      })
      .split(" ")
      .join(" ");

    let minDate = new Date(Date.now());
    minDate = minDate.toISOString().split("T")[0];

    // 21days from today
    let finalDate = new Date(Date.now() + 21 * 24 * 60 * 60 * 1000);
    finalDate = finalDate.toISOString().split("T")[0];

    return {
      minDate: minDate,
      maxDate: finalDate,
      pickupDate: finalDate,
      deliveryDate: null,
      currentTimeStamp: currentTimeStamp,
      submissionDateStr: submissionDateStr,
      submissionDate: submissionDate,
      today: today,
      columnDefs: null,
      defaultColDef: null,
      selectedRowData: null,
      gridApi: null,
      columnApi: null,
      autoGroupColumnDef: null,
      rowSelection: null,
      validForm: false,
      modalOpen: false,
      orderReqData: "",
      responseMsg: "",
      confirmedItems: "",
      billName: "",
      billingAddress: "400 Northwest Plaza Drive",
      billingCity: "St. Ann",
      billingState: "MO",
      billingZipCode: "63074",
      billingCountry: "US",
      deliveryAddress: "400 Northwest Plaza Drive",
      deliveryCity: "St. Ann",
      deliveryState: "MO",
      deliveryZipCode: "63074",
      deliveryCountry: "US",
      sameAsBilling: false,
      quantityUpdated: true,
      totalCube: 0,
      totalWeight: 0,
      customerName: "",
      userZoneID: "",
      getUser: "",
      accountNo: "",
      poNumber: "",
      orderRef: "",
      CorrelationNo: "",
      pickupCheck: false,
      customerNotes: "",
      priceListFor: "",
      DCnumber: ""
    };
  },
  components: {
    AgGridVue,
    Modal,
    Header,
    storeUsers
  },
  computed: {},
  mounted() {
    const dcDesc = this.$router.history.current.params.DCdesc;
    const DCnumber = this.$router.history.current.params.DCnumber;
    this.priceListFor = dcDesc
      ? dcDesc
      : JSON.parse(sessionStorage.getItem("priceListFor"));
    this.DCnumber = DCnumber
      ? DCnumber
      : JSON.parse(sessionStorage.getItem("DCnumber"));
    this.$root.$on("bv::modal::show", bvEvent => {
      if (bvEvent.type === "show") {
        this.dataRows = this.confirmedItems;
      }
    });

    this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
      if (modalId === "modal-multi-2") {
        this.$router.push("/PriceList");
      }
    });
  },
  created() {
    this.setUser();
    this.setUserStores();
  },
  beforeMount() {
    // this.selectedRowData = this.$store.state.selectedQtyItems;
    this.selectedRowData = JSON.parse(
      sessionStorage.getItem("userSelectedItems")
    );

    this.defaultColDef = {
      editable: false,
      flex: 1,
      resizable: true,
      supressMenu: true,
      sortable: true,
      suppressRowClickSelection: true,
      isRowSelectable: false,
      sortingOrder: ["desc", "asc"]
    };
    this.rowSelection = "multiple";
    this.columnDefs = [
      {
        headerName: "Cases Ordered",
        field: "quantity",
        minWidth: 130,
        sort: "desc"
      },
      { headerName: "Brand/Class", field: "brandClass", minWidth: 200 },
      {
        headerName: "Item ID/Description",
        field: "itemDetails",
        minWidth: 250
      },
      { headerName: "Status", field: "status" },
      { headerName: "UPC", field: "UPC", minWidth: 150 },
      { headerName: "Pack", field: "pack" },
      { headerName: "Size", field: "size" },
      { headerName: "Weight", field: "weight" },
      { headerName: "Cube", field: "cube" },
      { headerName: "TI", field: "HI" },
      { headerName: "HI", field: "HI" },
      { headerName: "Price($)", field: "price" }
    ];
  },
  methods: {
    setUser() {
      this.userData = this.$store.state.currentUser;
    },
    trimeVal(val) {
      let newVal = val.trim();
      let timVal = newVal.toLowerCase();
      return timVal;
    },
    getTimeStamp() {
      var currentdate = new Date();
      var date =
        currentdate.getDate() + 1 > 10
          ? currentdate.getDate()
          : "0" + currentdate.getDate();
      var month =
        currentdate.getMonth() + 1 > 10
          ? currentdate.getMonth()
          : "0" + currentdate.getMonth();
      var year = currentdate.getFullYear();
      return (
        year +
        "/" +
        month +
        "/" +
        date +
        " " +
        currentdate.toLocaleTimeString("en-US", {
          hour12: false,
          hour: "numeric",
          minute: "numeric"
        })
      );
    },
    setUserStores() {
      let userDet = {};

      storeUsers.forEach(ele => {
        if (
          ele.email &&
          this.trimeVal(this.userData.username) === this.trimeVal(ele.email)
        ) {
          userDet.userZoneID = ele.storeId;
          userDet.customerName = ele.customerName;
          userDet.accountNo = ele.accountNo;

          //Address fields

          userDet.billingAddress = ele.billToAddress.addressLine1;
          userDet.billingCity = ele.billToAddress.town;
          userDet.billingState = ele.billToAddress.state;
          userDet.billingZipCode = ele.billToAddress.zipCode;
          userDet.billingCountry = ele.billToAddress.country;

          userDet.deliveryAddress = ele.shipToAddress.addressLine1;
          userDet.deliveryCity = ele.shipToAddress.town;
          userDet.deliveryState = ele.shipToAddress.state;
          userDet.deliveryZipCode = ele.shipToAddress.zipCode;
          userDet.deliveryCountry = ele.shipToAddress.country;
        }
      });

      if (userDet && userDet.customerName) {
        this.userZoneID = userDet.storeId;
        this.customerName = userDet.customerName;
        this.accountNo = userDet.accountNo;

        //Address fields
        this.billingAddress = userDet.billingAddress;
        this.billingCity = userDet.billingCity;
        this.billingState = userDet.billingState;
        this.billingZipCode = userDet.billingZipCode;
        this.billingCountry = userDet.billingCountry;

        this.deliveryAddress = userDet.deliveryAddress;
        this.deliveryCity = userDet.deliveryCity;
        this.deliveryState = userDet.deliveryState;
        this.deliveryZipCode = userDet.deliveryZipCode;
        this.deliveryCountry = userDet.deliveryCountry;
      } else {
        this.userZoneID = "149";
        this.customerName = "Save-A-Lot Internal";
        this.accountNo = this.userZoneID;
      }

      this.orderRef = this.randomNumber(9);
      this.CorrelationNo = this.getCorrelationNo();
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;

      this.gridApi.selectAll();

      this.displayCubeDetails();
    },
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },
    pickupContext(ctx) {
      this.pickupDate = ctx.selectedYMD;
    },
    deliveryContext(ctx) {
      this.deliveryDate = ctx.selectedYMD;
    },
    getCurrentDate() {
      const current = new Date();
      this.currentDate = `${current.getDate()}/${current.getMonth() +
        1}/${current.getFullYear()}`;
    },
    randomNumber(length) {
      var text = "";
      var possible = "123456789";
      for (var i = 0; i < length; i++) {
        var sup = Math.floor(Math.random() * possible.length);
        text += i > 0 && sup == i ? "0" : possible.charAt(sup);
      }
      return Number(text);
    },
    getCorrelationNo() {
      var text =
        Math.floor(Math.random() * 1e4) +
        "-" +
        Math.floor(Math.random() * 1e4) +
        "-" +
        Math.floor(Math.random() * 1e4);
      return text;
    },
    onSelectionChanged() {
      let selectedNodes = this.gridApi.getSelectedNodes();
      let selectedData = selectedNodes.map(node => node.data);

      this.confirmedItems = selectedData;
    },
    copyBilling(e) {
      if (this.sameAsBilling) {
        this.deliveryAddress = this.billingAddress;
        this.deliveryCity = this.billingCity;
        this.deliveryState = this.billingState;
        this.deliveryZipCode = this.billingZipCode;
        this.deliveryCountry = this.billingCountry;
      }
    },
    resetRes() {
      this.responseMsg = "";
    },
    displayCubeDetails() {
      var totalW = 0;
      var totalC = 0;
      this.selectedRowData.forEach((e, i) => {
        if (e.quantity > 0) {
          totalW += e.weight * e.quantity;
          totalC += e.cube * e.quantity;
        }
      });
      this.totalWeight = parseFloat(totalW).toFixed(2);
      this.totalCube = parseFloat(totalC).toFixed(2);
      this.quantityUpdated = true;
    },
    getParams() {
      return {
        // prependContent: "Order Details (Ref: " + this.orderRef + ")",
        // appendContent: "appendContent content",
        columnGroups: true,
        allColumns: true,
        fileName: "export_items_" + this.orderRef
      };
    },
    exportExcelContent() {
      var htmls = "";
      var htmlRows = "";

      this.selectedRowData.forEach(ele => {
        htmlRows +=
          "<tr><td>" +
          ele.quantity +
          "</td>" +
          "<td>" +
          ele.brandClass +
          "</td>" +
          "<td>" +
          ele.itemDetails +
          "</td>" +
          "<td>" +
          ele.UPC +
          "</td>" +
          "<td>" +
          ele.pack +
          "</td>" +
          "<td>" +
          ele.size +
          "</td>" +
          "<td>" +
          ele.weight +
          "</td>" +
          "<td>" +
          ele.cube +
          "</td>" +
          "<td>" +
          ele.TI +
          "</td>" +
          "<td>" +
          ele.HI +
          "</td>" +
          "<td>" +
          ele.price +
          "</td>" +
          "<td>" +
          ele.status +
          "</td></tr>";
      });

      htmls =
        "<div id='tableWrap'><table><tr><th colspan='12' style='font-size: 16px;font-weight: bold;'>Order Details (Ref: " +
        this.orderRef +
        ")</th><tr></tr></tr><tr><th>Cases Ordered</th><th>Brand/Class</th><th>Item ID/Description</th><th>UPC</th><th>Pack</th><th>Size</th><th>Weight</th><th>Cube</th><th>TI</th><th>HI</th><th>Price</th><th>Status</th></tr>" +
        htmlRows +
        "</table></div>";
      return htmls;
    },
    onBtnExportCSV() {
      var params = this.getParams();
      this.gridApi.exportDataAsCsv(params);
    },
    onBtnExportExcel() {
      var htmls = this.exportExcelContent();
      var link = document.createElement("a");
      var uri = "data:application/xls;base64,";
      var template =
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
      var base64 = function(s) {
        return window.btoa(unescape(encodeURIComponent(s)));
      };

      var format = function(s, c) {
        return s.replace(/{(\w+)}/g, function(m, p) {
          return c[p];
        });
      };

      var ctx = {
        worksheet: "Worksheet",
        table: htmls
      };

      link.download = "export_items_" + this.orderRef + ".xls";
      link.href = uri + base64(format(template, ctx));
      link.click();
    },
    confirmOrder() {
      this.getAuthToken();
    },
    async getAuthToken() {
      const myMsal = new Msal.PublicClientApplication(msalConfig);
      const account = myMsal.getAllAccounts()[0];
      const accessTokenRequest = {
        scopes: msalConfig.tokenRequest,
        account: account
      };
      const accessTok = await myMsal
        .acquireTokenSilent(accessTokenRequest)
        .then(function(accessTokenResponse) {
          let accessToken = accessTokenResponse.accessToken;
          return accessToken;
        })
        .catch(err => {
          if (err.name === "InteractionRequiredAuthError") {
            return msalnstance
              .acquireTokenSilent(accessTokenRequest)
              .then(response => {});
          }
        });
      this.submitOrder(accessTok);
    },
    submitOrder(token) {
      const dataRowsItems =
        this.selectedRowData.length > 0
          ? this.selectedRowData.map(data => {
              return {
                item_number: data.item_number,
                item_description: data.item_description,
                item_seq: 1,
                quantity_ordered: data.quantity
              };
            })
          : [];
      const orderReqData = {
        Order: {
          msgheader: {
            businessfield01: "OrderForm",
            correlationid: this.CorrelationNo,
            countryoforigin: this.billingCountry,
            msgtype: "order",
            resubmitflag: "no",
            resubmittargetsysid: "null",
            sbsessionid: "null",
            sourcesysid: "gom-web",
            sourcesysmsgid: this.orderRef,
            sourcetimestamp: this.currentTimeStamp,
            targetsysid: "azure-sql-staging"
          },
          msgbody: {
            OrderDetails: {
              order_reference: this.orderRef,
              ordered_by: this.userData.username,
              purchase_order_number: this.poNumber,
              notes: this.customerNotes,
              pickup_date: this.pickupCheck ? this.pickupDate : null,
              delivery_date: !this.pickupCheck ? this.pickupDate : null,
              submission_date: this.submissionDateStr,
              DC: {
                name: this.priceListFor,
                number: this.DCnumber
              },
              customer: {
                account_number: this.accountNo,
                name: this.customerName
              },
              address: [
                {
                  line1: this.billingAddress,
                  line2: null,
                  town: this.billingCity,
                  state: this.billingState,
                  postcode: this.billingZipCode,
                  country: this.billingCountry,
                  type: "BillTo"
                },
                {
                  line1: this.deliveryAddress,
                  line2: null,
                  town: this.deliveryCity,
                  state: this.deliveryState,
                  postcode: this.deliveryZipCode,
                  country: this.deliveryCountry,
                  type: "ShipTo"
                }
              ],
              Items: dataRowsItems
            }
          }
        }
      };
      this.orderReqData = orderReqData;
      const headers = {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": msalConfig.apiKey,
        "Access-Control-Allow-Origin": true,
        Authorization: "Bearer " + token
      };

      axios
        .post(msalConfig.orderApi, orderReqData, { headers })
        .then(response => {
          this.responseMsg =
            "Your order successfully submitted with Reference id " +
            this.orderRef;
          sessionStorage.userSelectedItems = null;
          this.$bvModal.show("modal-multi-2");
        })
        .catch(error => {
          this.responseMsg = error.message;
          this.responseMsg = "Please reach out to Save-A-Lot team.";
          this.$bvModal.show("modal-multi-3");
        });
    }
  }
};
</script>

<style lang="scss">
#modal-multi-2,
#modal-multi-3 {
  .modal-backdrop {
    opacity: 1;
  }
  .modal-dialog {
    max-width: 50%;
    margin-top: 10%;
  }
  .modal-content {
    min-height: 15rem;
  }
}
#app {
  .datePicker {
    input {
      font-size: 14px;
      margin-right: 12px;
      &#pickupCheck {
        margin: 3px 5px;
      }
    }
  }
  .card-body {
    padding: 0 1rem;
  }
  .form-control.customerNotes {
    border: 1px solid #727070;
  }
  .orderFormHeader {
    border: 1px solid #babfc7;
    .card-header {
      border: 0;
      background: none;
      box-shadow: none;
      &:focus,
      &:hover {
        border: 0;
        background: none;
        box-shadow: none;
        outline: 0;
      }
      .btn-check:focus + .btn-light,
      .btn-light:focus {
        border: 0;
        background: none;
        box-shadow: none;
        outline: 0;
      }
      a.btn-light {
        font-weight: 600;
        padding: 0;
      }
      .btn-block.collapsed {
        .fa-chevron-down {
          display: block;
        }
        .fa-chevron-up {
          display: none;
        }
      }
      .btn-block.not-collapsed {
        .fa-chevron-down {
          display: none;
        }
        .fa-chevron-up {
          display: block;
        }
      }
    }
  }
  .orderForm {
    .ag-theme-alpine .ag-row {
      border-bottom: 1px solid #babfc7;
    }
    .btn-order {
      padding: 0.25rem 0.5rem;
      border-radius: 0.2rem;
      font-size: 0.875rem;
      background: #14377d;
      border: 1px solid #14377d;
      outline: 0;
      &:hover {
        background: #285dc5;
      }
    }
    .btn-excel {
      padding: 0.25rem 0.5rem;
      font-size: 0.875rem;
      border-radius: 0.2rem;
      margin-left: 10px;
      background: #ffffff;
      border: 1px solid #000;
      outline: 0;
      box-shadow: none;
      &:hover {
        border: 1px solid #858383;
      }
    }
    .btn-outline-success {
      background: #ffffff;
      border: 1px solid #198754;
      color: #000;
      outline: 0;
      box-shadow: none;
      &:hover {
        border: 1px solid #5dd89e;
      }
    }
  }
}
</style>
